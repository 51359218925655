<template>
  <div class="full-width">
    <document-toolbar
      :delete-document="deleteAgingScenario"
      :show-cancel="!readonly"
      :show-delete="!readonly"
      :show-save="!readonly"
      :revert="refreshDocument"
      :save="saveDocument"
      :tab="tab"
      v-on="$listeners"
      no-history
    >
    </document-toolbar>
    <document-in-tab>
      <cot-form
        ref="form"
        :value="tab.canSave"
      >
        <cot-form
          ref="headerForm"
          v-model="headerForm"
          @input="validateMainForm"
        >
          <grid
            item-min-width="300px"
            class="align-center "
          >
            <translations
              :is-new="documentIsNew"
              :label="$t('t.Name')"
              :rules="nameRules"
              :translations.sync="computedName"
              class="mr-4"
            />
            <translations
              :is-new="documentIsNew"
              :label="$t('t.Description')"
              :translations.sync="computedDescription"
            />
            <v-autocomplete
              :label="$t('t.Documents')"
              :items="documentTypes"
              item-text="text"
              item-value="id"
              :rules="required"
              v-model="computedViewType"
            />
          </grid>
          <aging-params
            v-if="computedViewType"
            :document.sync="computedParams"
            :view-type="computedViewType"
          />
          <aging-buckets
            :ranges.sync="computedRanges"
            :range-translated-values.sync="computedRangeTranslatedValues"
            :is-pristine="tab.isPristine"
          />
        </cot-form>
      </cot-form>
    </document-in-tab>
  </div>
</template>

<script>
import DocEdit from '@/mixins/document-editor'

export default {
  mixins: [DocEdit],
  components: {
    CotForm: () => import('@/components/cot-form'),
    DocumentInTab: () => import('@/components/document-in-tab'),
    DocumentToolbar: () => import('@/components/document-toolbar'),
    AgingBuckets: () => import('./aging-buckets'),
    AgingParams: () => import('./aging-params'),
    Grid: () => import('@/components/grid'),
    Translations: () => import('@/components/translations')
  },
  computed: {
    cacheType () {
      return DocEdit.CacheType.AgingScenarioDetail
    },
    computedViewType: {
      get () { return this.document?.viewType },
      set (v) {
        this.document.viewType = v
        if (v) {
          this.$nextTick(() => {
            this.document.params = {}
          })
        }
      }
    },
    computedName: {
      get () { return this.document?.name || {} },
      set (v) {
        this.document.name = this.lodash.cloneDeep(v)
      }
    },
    computedDescription: {
      get () { return this.document?.description || {} },
      set (v) {
        this.document.description = this.lodash.cloneDeep(v)
      }
    },
    computedRanges: {
      get () { return this.document?.ranges ?? [] },
      set (v) {
        this.document.ranges = this.lodash.cloneDeep(v)
      }
    },
    computedRangeTranslatedValues: {
      get () { return this.document?.rangeTranslatedValues ?? [] },
      set (v) {
        this.document.rangeTranslatedValues = this.lodash.cloneDeep(v)
      }
    },
    computedParams: {
      get () { return this.document?.params },
      set (v) {
        this.document.params = this.lodash.cloneDeep(v)
      }
    },
    nameRules () {
      const MIN = 1
      const MAX = 100
      return [
        v => !!v.length || this.$t('t.IsRequired'),
        v => (v.length >= MIN && v.length <= MAX) || this.$t('t.Length')
      ]
    },
    id () {
      return this.tab.documentId
    },
    readonly () {
      return !this.$store.getters.currentUserHasPermission('AgingBucketSettings')
    }
  },
  data () {
    return {
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument),
      documentTypes: [
        { id: 'accounts', text: this.$t('t.Accounts') },
        { id: 'allocation-announcements', text: this.$t('t.AllocationAnnouncements') },
        { id: 'collaboration-tasks', text: this.$t('t.CollaborationTasks') },
        { id: 'disputes', text: this.$t('t.Disputes') },
        { id: 'invoices', text: this.$t('t.Invoices') },
        { id: 'promises', text: this.$t('t.Promises') },
        { id: 'unapplied-cashes', text: this.$t('t.UnappliedCashes') },
        { id: 'work-items', text: this.$t('t.WorkItems') }
      ],
      headerForm: false,
      required: [v => (!!v && !this.lodash.isEqual(v, {}) && !this.lodash.isEqual(v, { id: null })) || this.$t('t.IsRequired')]
    }
  },
  methods: {
    validateMainForm () {
      this.$nextTick(() => this.$emit('document-can-save', { tab: this.tab, value: this.$refs?.headerForm?.value }))
    },
    cancel () {
      this.refreshDocument()
    },
    async deleteAgingScenario () {
      const apiUrl = '/core/v6/aging'
      this.$http().delete(`${apiUrl}/${this.id}`).then(async () => {
        this.$router.push('/').catch(e => console.log(e))
      }).catch(e => {
        this.$store.dispatch(
          'showErrorSnackbar',
          e.response?.data?.message
        )
      })
    },
    emitDocument () {
      const doc = this.document

      if (!this.lodash.isEqual(doc, this.document)) {
        this.$emit('update:document', this.lodash.cloneDeep(doc))
      }
    },
    async refreshDocumentHook () {
      if (this.documentIsNew) {
        this.document = Object.assign(
          {},
          this.document,
          {
            name: this.document?.name || {},
            description: this.document?.description || {},
            viewType: this.document?.viewType,
            ranges: [],
            rangeTranslatedValues: [],
            params: { ty: null, c: null }
          }
        )
        this.dataDocumentEmit()
      }
      this.documentTypes.sort(function (a, b) {
        if (a.text > b.text) {
          return 1
        }
        if (a.text < b.text) {
          return -1
        }
        return 0
      })
    },
    async save () {
      const apiUrl = '/core/v6/aging'
      if (this.documentIsNew) {
        const response = await this.$http().post(apiUrl, this.document)
        this.$nextTick(() => {
          this.$router.push('/aging-scenarios/' + response.data?.id)
        })
        return response
      } else {
        return await this.$http().put(`${apiUrl}/${this.id}`, this.document)
      }
    }
  },
  props: {
    tab: Object
  },
  watch: {
  }
}
</script>

<style lang="stylus" scoped>
.full-width
  width 100%
</style>
